<template>
  <div class="single-bridge-common">
    <div class="mb-2">
      <el-button class="btn-primary" @click="goto()">新增</el-button>
    </div>
    <div class="table-wrapper" >
      <c-table
          :index="true"
          :data="pageData"
          :tableColumns="tableColumns"
          v-loading="pageLoading"
          :stripe="true"
          :pagination="pageParams"
          @change="pageGet"
      >
        <template v-slot:custom="{ row }">
          <span class="opt-edit mr-6" @click="goto(row)">编辑</span>
          <span class="opt-delete" @click="pageDel(row.id)">删除</span>
<!--          <span class="opt-delete">删除</span>-->
        </template>
      </c-table>
    </div>
    <el-dialog
        :close-on-click-modal="false"
        :title="title"
        :visible.sync="dialogVisible"
        :before-close="beforeDialogClose"
        :destroy-on-close="true"
        width="900px"
    >
      <div class="form-wrapper manage-scroll">
        <el-form ref="form" :model="form.model" :rules="form.rules" label-width="120px">
          <el-card class="form-card">
            <el-row :gutter="20" class="w-full">
              <el-col :span="12">
                <el-form-item  label="名称" prop="name">
                  <el-input v-model="form.model.name"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item  label="传感器测点" prop="sensorDetailIdList">
                  <el-cascader
                      :options="sensorList"
                      :props="props"
                      collapse-tags
                      v-model="form.model.sensorDetailIdList"
                      clearable></el-cascader>
                </el-form-item>
              </el-col>
            </el-row>
          </el-card>
          <el-card class="form-card" v-for="(item,index) in form.model.warnConfigList" :key="index">
            <el-row :gutter="20" class="w-full">
              <el-col :span="12">
                <el-form-item label="阈值类型" :prop="'warnConfigList.' + index + '.warnType'" :rules="form.rules.warnType">
                  <el-select v-model="item.warnType" placeholder="请选择" @change="changeWarn(index)">
                    <el-option label="超过固定值报警" :value="0"></el-option>
                    <el-option label="超特征值报警" :value="1"></el-option>
                    <el-option label="超限次数报警" :value="2"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="item.warnType !== 2">
                <el-form-item  label="预警上限" :prop="'warnConfigList.' + index + '.warnUpper'">
<!--                  <el-input type="number" v-model="item.warnUpper" disabled/>-->
                  <el-input type="number" v-model="item.warnUpper"/>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="item.warnType !== 2">
                <el-form-item  label="预警下限" :prop="'warnConfigList.' + index + '.warnFloor'">
<!--                  <el-input type="number" v-model="item.warnFloor" disabled/>-->
                  <el-input type="number" v-model="item.warnFloor"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item  label="报警等级" :prop="'warnConfigList.' + index + '.warnLevel'" :rules="form.rules.warnLevel">
<!--                  <el-select @change="(val) => {getLimit(val,index)}" v-model="item.warnLevel" placeholder="请选择">-->
                  <el-select v-model="item.warnLevel" placeholder="请选择">
                    <el-option label="一级预警" :value="1"></el-option>
                    <el-option label="二级预警" :value="2"></el-option>
                    <el-option label="三级预警" :value="3"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="item.warnType === 1">
                <el-form-item label="时间周期" :prop="'warnConfigList.' + index + '.timeCycle'" :rules="form.rules.timeCycle">
                  <el-select v-model="item.timeCycle" placeholder="请选择">
                    <el-option label="1分钟" :value="0"></el-option>
                    <el-option label="10分钟" :value="1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item  label="数值类型" :prop="'warnConfigList.' + index + '.valueType'" :rules="form.rules.valueType">
                  <el-select v-model="item.valueType" placeholder="请选择">
                    <el-option label="原始值" :value="0"></el-option>
                    <el-option label="绝对值" :value="1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="item.warnType === 1">
                <el-form-item label="特征值类型" :prop="'warnConfigList.' + index + '.featureType'" :rules="form.rules.featureType">
                  <el-select v-model="item.featureType" placeholder="请选择">
                    <el-option label="平方根" :value="0"></el-option>
                    <el-option label="均方根" :value="1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="item.warnType === 2">
                <el-form-item  label="超过预警次数" :prop="'warnConfigList.' + index + '.moreThanWarnNum'" :rules="form.rules.moreThanWarnNum">
                  <el-input type="number" v-model="item.moreThanWarnNum"/>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="item.warnType === 2">
                <el-form-item  label="超过预警次数的预警等级" :prop="'warnConfigList.' + index + '.moreThanWarnLevel'" :rules="form.rules.moreThanWarnLevel">
                  <el-input type="number" v-model="item.moreThanWarnLevel"/>
                </el-form-item>
              </el-col>
            </el-row>
            <div class="form-footer">
              <el-button type="danger" @click="removeForm(index)">删除配置</el-button>
              <el-button type="primary" v-if="index === form.model.warnConfigList.length - 1" @click="addForm()">添加配置</el-button>
            </div>
          </el-card>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="resetFields">取 消</el-button>
        <el-button class="btn-primary" @click="submit('form')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import MPage from "@/mixins/MPage.js";
  import MHandle from "@/mixins/MHandle.js";
  import Configs from "./config/index";
  let mPage = MPage({
    pagePath: "/warnManage/page",
    pageDelPath: "/warnManage/delete",
    pageDelsPath: "",
    pageParams: {},
  });
  let mHandle = MHandle({
    handleGetPath: "",
    handleInsertPath: "",
    handleUpdatePath: "",
    handleDownLoadPath: "",
    handleData: {},
  });
  export default {
    mixins: [mPage, mHandle],
    data() {
      let { tableColumns } = Configs(this);
      return {
        tableColumns,
        dialogVisible: false,
        title:'',
        pageData:[{id:1}],
        form:{
          model:{
            warnConfigList:[{
              warnType: '',
              valueType: '',
              warnUpper:'',
              warnFloor:'',
              warnLevel:'',
              timeCycle:'',
              featureType:'',
              moreThanWarnNum:'',
              moreThanWarnLevel:''
            }]
          },
          rules:{
            name:[
              { required: true, message: '请填写名称', trigger: 'blur' }
            ],
            sensorDetailIdList:[
              { required: true, message: '请选择传感器测点', trigger: 'blur' }
            ],
            warnType:[
              { required: true, message: '请选择阈值类型', trigger: 'blur' }
            ],
            warnLevel:[
              { required: true, message: '请选择报警等级', trigger: 'blur' }
            ],
            timeCycle:[
              { required: true, message: '请选择时间周期', trigger: 'blur' }
            ],
            valueType:[
              { required: true, message: '请选择数值类型', trigger: 'blur' }
            ],
            featureType:[
              { required: true, message: '请选择特征值类型', trigger: 'blur' }
            ],
            moreThanWarnNum:[
              { required: true, message: '请填写超过预警次数', trigger: 'blur' }
            ],
            moreThanWarnLevel:[
              { required: true, message: '请填写超过预警次数的预警等级', trigger: 'blur' }
            ]
          }
        },
        sensorList:[],
        props:{
          multiple: true,
          emitPath:false,
          value:'id',
          label:'name',
          children:'sensorDetailList'
        },
        isAdd:true
      };

    },
    created() {
      this.getSensor()
    },
    methods: {
      getSensor(){
        this.$http.get("/sensorType/getAllAndDetail").then(res => {
          if(res.success){
            if (res.data){
              this.sensorList = res.data
            }
          }
        })
      },
      addForm(){
        this.form.model.warnConfigList.push({
          warnType: '',
          valueType: '',
          warnUpper:'',
          warnFloor:'',
          warnLevel:'',
          timeCycle:'',
          featureType:'',
          moreThanWarnNum:'',
          moreThanWarnLevel:''
        })
      },
      removeForm(index){
        this.form.model.warnConfigList.splice(index,1)
      },
      changeWarn(i){
        this.form.model.warnConfigList[i].warnUpper = ''
        this.form.model.warnConfigList[i].warnFloor = ''
        this.form.model.warnConfigList[i].warnLevel = ''
        this.form.model.warnConfigList[i].timeCycle = ''
        this.form.model.warnConfigList[i].featureType = ''
        this.form.model.warnConfigList[i].moreThanWarnNum = ''
        this.form.model.warnConfigList[i].moreThanWarnLevel = ''
      },
      goto(data) {
        this.form.model = {
          warnConfigList: [{
            warnType: '',
            valueType: '',
            warnUpper:'',
            warnFloor:'',
            warnLevel:'',
            timeCycle:'',
            featureType:'',
            moreThanWarnNum:'',
            moreThanWarnLevel:''
          }]
        }
        if (data) {
          this.title = "编辑预警配置";
          this.isAdd = false
          this.getDetail(data)
        } else {
          this.title = "新增预警配置";
          this.isAdd = true
          this.dialogVisible = true;
        }
      },
      getDetail(data){
        this.$http.get("/warnManage/getDetailById",{params:{id:data.id}}).then(res => {
          if(res.success){
            if (res.data){
              if (!res.data.warnConfigList){
                res.data.warnConfigList = [{warnType: '', valueType: '', warnUpper:'', warnFloor:'', warnLevel:'', timeCycle:'', featureType:'', moreThanWarnNum:'', moreThanWarnLevel:''}]
              }
              this.form.model = res.data
              this.dialogVisible = true;
            }
          }
        })
      },
      beforeDialogClose(done) {
        done();
      },
      submit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let api = ''
            if (this.isAdd === true){
              api = "/warnManage/add"
            } else {
              api = "/warnManage/update"
            }
            // this.form.model.sensorDetailIdList = [this.form.model.sensorDetailIdList];
            this.$http.post(api,this.form.model).then(res=>{
              if(res.success){
                this.$message.success("操作成功");
                this.pageGet()
                this.dialogVisible = false;
              } else {
                this.$message.error(res.msg)
              }
            });
          }
        })
      },
      resetFields() {
        this.dialogVisible = false;
      },
      getLimit(val, index) {
        if (!this.form.model.sensorDetailIdList) {
          this.$message.warning('请先选择测点');
          this.form.model.warnConfigList[index].warnLevel = null;
          return;
        }
        let params = {
          detailIdList: [this.form.model.sensorDetailIdList],
          limitLevel: val,
        };
        this.$http.post("/warnManage/limitData", params).then(res => {
          if (res.code === 200) {
            console.log(this.form.model.warnConfigList[index]);
            if (res.data[0].limitUpper !== null) {
              this.form.model.warnConfigList[index].warnUpper = res.data[0].limitUpper.toString();
              this.form.model.warnConfigList[index].warnFloor = res.data[0].limitFloor.toString();
            } else {
              this.form.model.warnConfigList[index].warnUpper = '';
              this.form.model.warnConfigList[index].warnFloor = '';
            }
          } else {
            this.$message.error(res.msg);
          }
        })
      }
    },
  };
</script>

<style scoped>
  .el-card{
    margin-bottom: 10px;
  }
  .form-footer{
    display: flex;
    justify-content: flex-end;
  }
</style>
